<template>
  <div class="bg">
    <div class="wrap cont6">
      <ul class="parent">
        <li v-for="(item,index) in list" :key="index">
          <figure class="item-wrap">
            <div class="img">
              <img :src="item.src">
            </div>
            <figcaption>
              <h3 class="title">{{item.title}}</h3>
              <div class="desc">{{item.desc}}</div>
            </figcaption>
          </figure>

        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      list: {
        type: Array,
        default: () => []
      }
    }
  }
</script>


<style lang="less" scoped>
  .bg {
    overflow: hidden;
    position: relative;

    &:after {
      background: url(../../assets/img2.jpg) no-repeat center;
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      filter: blur(2px);
      z-index: 0;
      opacity: 0.25;
      //transform: scale(1.2);

    }
  }

  .cont6 {
    padding: 60px 0;
    position: relative;
    z-index: 1;

    .parent {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 20px;

      li {
        padding: 10px;
        background: #fff;
        cursor: pointer;

        .img {
          overflow: hidden;
        }

        img {
          width: 100%;
          transition: all 0.25s ease;
        }

        &:hover {
          img {
            transform: scale(1.1);
          }
        }

        .title {
          font-size: 18px;
          line-height: 36px;
          text-align: left;
        }

        .desc {
          font-size: 14px;
          color: #acb2ba;
          text-align: left;
        }
      }
    }
  }
</style>