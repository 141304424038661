<template>
  <div class="bg">
    <div class="wrap cont7">
      <ul class="parent">
        <li v-for="(item,index) in list" :key="index">
          <figure class="item-wrap">
            <div class="img">
              <img :src="item.src">
              <figcaption>
                <h3 class="title">{{item.title}}</h3>
                <div class="desc">{{item.desc}}</div>
               
              </figcaption>
            </div>
          </figure>
          <ul class="project">
            <li v-for="(itm,idx) in item.project" :key="idx">
              ● {{itm.title}}
            </li>

          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      list: {
        type: Array,
        default: () => []
      }
    }
  }
</script>


<style lang="less" scoped>
  .bg {
    overflow: hidden;
    position: relative;

    &:after {
      background-image: url(../../assets/bbg.png);
      background-repeat: no-repeat;
      background-size: cover;
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      //filter: blur(2px);
      z-index: 0;
      //opacity: 0.25;
      //transform: scale(1.2);

    }
  }

  .cont7 {
    padding: 60px 0;
    position: relative;
    z-index: 1;

    .parent {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;

      li {
        padding: 10px;
        background: #fff;

        .img {
          overflow: hidden;
          position: relative;

          figcaption {
            position: absolute;
            width: 100%;
            height: 100%;
            text-align: center;
            top: 0;
            background: rgba(0, 0, 0, .75);
            color: #FFF;

            &:hover {
              opacity: 1;
              background-image: linear-gradient(to bottom, #00000080, #0e93ff33);
              transition: 0.7s opacity;
            }

            .title {
              margin-top: 40px;
              font-size: 18px;
              line-height: 36px;

            }

            .desc {
              font-size: 14px;
              margin-bottom: 20px;

            }
          }


        }

        img {
          width: 100%;
          transition: all 0.25s ease;
        }

        &:hover {
          img {
            transform: scale(1.1);
          }
        }

        .project {
          text-align: center;
        }
      }
    }
  }
</style>