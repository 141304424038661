<template>
  <div class="cont5 wrap">
    <ul class="parent">
      <li v-for="(item,index) in list" :key="index">
        <i :class="item.icon"></i>
        <h3 class="title">{{item.title}}</h3>
        <div class="desc">{{item.desc}}</div>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    props: {
      list: {
        type: Array,
        default: () => []
      }
    }
  }
</script>

<style lang="less" scoped>
  .cont5 {
    padding-bottom: 60px;

    .parent {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 10px;


      li {
        padding: 10px;
        border: 2px solid #2c3e50;
        text-align: center;

        &:hover {
          cursor: pointer;
          border-color: #2979ff;

          [class^=el-icon-] {
            color: #2979ff;
          }

          .title {
            color: #2979ff;
          }
        }

        .title {
          font-size: 18px;
          line-height: 36px;
        }

        .desc {
          font-size: 14px;
          color: #acb2ba;
          text-align: left;
        }

        [class^=el-icon-] {
          font-size: 64px;
        }
      }
    }
  }
</style>