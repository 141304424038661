<template>
  <div class="container">

    <Head></Head>
    <main>
      <div class="banner">
        <div class="wrap">
          <div class="txt">
            <h1>电商ERP整体解决方案</h1>
              <div class="subtitle">商品管理、库存管理、订单管理、财务管理、供应商管理、门店管理、分销管理</div>
              <div>· 实时精准库存同步，智能库存预警，防缺货防超卖</div>

              <div>· 多店铺批量快速打单、发货，效率提升90%</div>

              <div>· 稳定、安全，让您体验极致的电商管理软件</div>
          </div>
          <div class="img">
            <img src="../assets/Class.svg">
          </div>
        </div>
      </div>
      <ContBolck :title="cont7.title" :sub-title="cont7.subTitle">
        <Cont7 :list="cont7.records"></Cont7>
      </ContBolck>
      <ContBolck :title="cont5.title" :sub-title="cont5.subTitle">
        <Cont5 :list="cont5.records"></Cont5>
      </ContBolck>
      <ContBolck :title="cont6.title" :sub-title="cont6.subTitle">
        <Cont6 :list="cont6.records"></Cont6>
      </ContBolck>
      <!--
      <ContBolck :title="cont8.title" :sub-title="cont8.subTitle">
        <Cont8></Cont8>
      </ContBolck>
      -->
    </main>
    <Foot></Foot>
  </div>
</template>

<script>
 // import Cont8 from '../components/content/cont8'
  import Cont7 from '../components/content/cont7'
  import Cont6 from '../components/content/cont6'
  import Cont5 from '../components/content/cont5'
  import ContBolck from '../components/contBolck'
  import Head from '../components/head'
  import Foot from '../components/foot'
  import Nav from '../components/Nav'
  export default {
    components: {
    //  Cont8,
      Cont7,
      Cont6,
      Cont5,
      ContBolck,
      Head,
      Foot,
    },
    data() {
      return {

        cont7: {
          title: '企赢动力电商系统开发业务',
          subTitle: '提供企业级高端定制开发服务',
          records: [{
            title: '商城系统开发',
            desc: '定制线上商城',
            src: require('../assets/ec01.png'),
            project: [{
                title: '电商平台'
              },
              {
                title: '批发商城'
              },
              {
                title: '跨境商城'
              },
              {
                title: '企业官网'
              }
            ]
          }, {
            title: '微商城开发',
            desc: '用户之前形成快速传播，拉新引流',
            src: require('../assets/ec02.png'),
            project: [{
                title: ' 分销系统'
              },
              {
                title: ' 微商购物'
              },
              {
                title: ' 智慧门店'
              },
              {
                title: ' 美食外卖'
              }
            ]
          }, {
            title: '小程序开发',
            desc: '用户之前形成快速传播，拉新引流',
            src: require('../assets/ec03.png'),
            project: [{
                title: ' 电商小程序'
              },
              {
                title: ' 门店小程序'
              },
              {
                title: ' 收银小程序'
              },
              {
                title: ' 到家小程序'
              }
            ]
          }, {
            title: 'APP开发',
            desc: '用户之前形成快速传播，拉新引流',
            src: require('../assets/ec04.png'),
            project: [{
                title: ' 原生APP开发'
              },
              {
                title: ' 混合APP开发'
              },
              {
                title: ' APP H5开发'
              },
              {
                title: ' 应用市场发布'
              }
            ]
          }]
        },
        cont5: {
          title: '为什么选择企赢动力',
          subTitle: '企赢动力拥有专业的技术开发、服务团队，拥有成熟的商城系统，满足众多企业的商城个性化定制需求',
          records: [{
            title: '系统稳定',
            desc: '采用主流开发语言开发，适应各种运行环境',
            icon: 'el-icon-ship'
          }, {
            title: '专注电商',
            desc: '采用主流开发语言开发，适应各种运行环境',
            icon: 'el-icon-shopping-cart-1'
          }, {
            title: '专业开发',
            desc: '采用主流开发语言开发，适应各种运行环境',
            icon: 'el-icon-set-up'
          }, {
            title: '功能强大',
            desc: '采用主流开发语言开发，适应各种运行环境',
            icon: 'el-icon-trophy'
          }, {
            title: '安全放心',
            desc: '采用主流开发语言开发，适应各种运行环境',
            icon: 'el-icon-umbrella'
          }]
        },
        cont6: {
          title: '提供一体化的电商解决方案',
          subTitle: '强大的前后台功能，满足各种运营需求',
          records: [{
              title: '会员分销体系',
              desc: '用户之前形成快速传播，拉新引流',
              src: require('../assets/ec05.jpg')
            },
            {
              title: '打通线上线下商业体系',
              desc: '用户之前形成快速传播，拉新引流',
              src: require('../assets/ec06.jpg')
            },
            {
              title: '多用户多商户在线交易',
              desc: '用户之前形成快速传播，拉新引流',
              src: require('../assets/ec07.jpg')
            },
            {
              title: '多用户商城系统',
              desc: '用户之前形成快速传播，拉新引流',
              src: require('../assets/ec08.jpg')
            },
            {
              title: '建立移动商城传播体系',
              desc: '用户之前形成快速传播，拉新引流',
              src:require('../assets/ec09.jpg')
            },
            {
              title: '连接门店，扩展渠道',
              desc: '用户之前形成快速传播，拉新引流',
              src: require('../assets/ec10.jpg')
            }
          ]
        },
        cont8: {
          title: '电商系统开发全流程',
          subTitle: '公开透明的报价，多对一的售后服务'
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    main {
      flex: 1;

      .banner {

        background: #2979FF;

        .wrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 520px;
          line-height: 36px;
          color: #FFF;

          h1 {
            font-size: 36px;
            font-weight: bold;
          }

          .subtitle {
            font-size: 20px;
          }

          .img {
            width: 35%;
            overflow: hidden;
            height: 100%;
            display: flex;
            align-items: flex-end;

            img {
              width: 100%;
            }
          }

        }
      }
    }
  }
</style>