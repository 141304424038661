<template>
  <ul id="nav">
    <li v-for="(item,index) in navList" :key="index">
      <el-link :href="item.link">{{item.title}}</el-link>
      <!-- <router-link :to="item.link">{{item.title}}</router-link> -->
    </li>
  </ul>
</template>

<script>
  export default {
    props: {
      navList: {
        type: Array,
        default: () => []
      }
    }
  }
</script>

<style lang="less" scoped>
  #nav {
    display: flex;
    align-items: center;

    a {
      padding: 10px 15px;
      font-size: 16px;
    }
  }
</style>